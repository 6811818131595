import "./footer.css";
import packageJson from '../../../package.json';

function Footer() {
  return (
    <footer className="text-lg-start" style={{ background: "#F4F9FF" }}>
      <hr className="m-0" />
      <div className="text-center" style={{ width: "100%" }}>
        <br />
        <span style={{ fontWeight: 500 }}>@ 2024 </span>
        <a href="https://clifty.io" rel="noreferrer" target="_blank">
          Clifty Inc.
        </a>
        <br />
      </div>
      <div style={{ textAlign: "right", paddingRight: "10px" }}>
        <span style={{ fontWeight: 200, color: "Silver" }}>v{packageJson.version}</span>
      </div>
    </footer>
  );
}

export default Footer;