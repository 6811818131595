import { useNavigate } from "react-router";
import cliftylogo from "../../images/clifty-logo.png";
import "./header.css";

function Header({ login, setLogin, setmsg }) {
  const navigate = useNavigate();
  const handlesignout = () => {
    setLogin(false);
    setmsg("Signed out");
    navigate("");
    localStorage.removeItem("user");
  };
  return (
    <header className="">
      <ul className="text">
        <li
          className="page"
          onClick={login ? () => navigate("/dashboard") : null}
        >
          <img src={cliftylogo} className="img" alt="Clifty Logo" />
          <h3 className="pins">Admin</h3>
        </li>
        {login && (
          <>
            <li>
              <a href="/dashboard">Dashboard</a>
            </li>
            <li>
              <a href="/dashboard/users">Users</a>
            </li>
            <li>
              <a href="/dashboard/orgs">Orgs</a>
            </li>
            <li>
              <a href="/dashboard/events">Events</a>
            </li>
            <li>
              <a href="/" onClick={handlesignout}>Signout</a>
            </li>
          </>
        )}
      </ul>
    </header>
  );
}

export default Header;
